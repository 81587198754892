window.general = new Vue({
    el: '#v-app-general',
    data: {
        lang: 'fr',
        userId: 0,
        params: [],
        nbpanier: 0
    },
    methods: {
        ChangeUrl: function(arr){
            var file = window.location.pathname.split('/');
            file = file[file.length - 1].split('?');
            file = file[file.length - 1];
            url = "?";
            urlAfter = '/' + this.lang+ '/search/';
            for(var i in arr)
            {
                if(arr[i]!="#" && arr[i]!=""){
                    if (['page', 'search_refklaxcar'].indexOf(i) === -1) url += i + "=" + arr[i] + "&";else urlAfter += arr[i] + '/';
                }


            }
            url = url.substr(0, url.length - 1);
            window.history.pushState("","", urlAfter + url);
        }
    }
});
var globalTimeout = null;
window.reload = () => window.location.reload();
window.client = new Vue({
    el: '#v-app-client',
    data: {
        totalPrix: typeof total_prix !== 'undefined' ? total_prix : 0,
        Loading: false,
        ongletsCompte: {
            "infos": true,
            "histo": false
        }
    },
    methods: {
        Onglet: function Onglet(o) {
            for (var i in this.ongletsCompte) {
                this.ongletsCompte[i] = false;
            }

            this.ongletsCompte[o] = true;
        },
        AddPanier: function AddPanier(numero, lien) {

            this.Loading = true;
            var app = this;
            $.get(lien + "?numero=" + numero).done(function () {
                app.Loading = false;
                general.nbpanier++;
            });
        },
        ChangePanier: function ChangePanier(event, ajouter, lien) {
            $e = $(event.target);
            var prix = parseFloat($e.parent().parent().find('.art-prix').text());
            var stock = $($e.parent().find('input')[0]);
            var max = parseInt($e.parent().parent().find('.art-stock').text());

            max = max ? max : Infinity;
            var numero = $e.parent().parent().find('.art-num').text();

            if (ajouter && max > parseInt(stock.val()) || !ajouter && parseInt(stock.val()) > 0) {
                this.totalPrix += ajouter ? prix : -prix;
                this.totalPrix = Math.round(this.totalPrix * 100, 2) / 100;
                this.SendAdd(event,ajouter, numero, parseInt(stock.val()),stock, this.totalPrix);
            }

            if (!ajouter && parseInt(stock.val()) - 1 < 0) {
                $e.parent().parent().remove();
            }
        },
        ChangePanierAuto: function ChangePanierAuto(event, ajouter, lien, prix, stock, max, numero) {

            var elem = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
            elem = !elem ? $(event.target).parent().find('.quant-infos') : elem;
            max = max ? max : Infinity;
            stock = !stock ? $(elem).val() : stock;


            if (ajouter && max > parseInt(stock) || !ajouter && parseInt(stock) > 0) {
                this.Loading = true;
                var app = this;
                elem.val(parseInt(stock) + (ajouter ? 1 : -1));
                this.SendAdd(event,ajouter, numero, parseInt(stock),elem);
            }
        },
        ChangePanierBy: function ChangePanierBy(event, lien, numero) {
            if (globalTimeout != null) clearTimeout(globalTimeout);

            globalTimeout = setTimeout(function(app,event, lien, numero){

                $(event.target).val($(event.target).val().replace(/[^0-9\.]+/g, ''));
                var stock = $(event.target).val();
                app.Loading = true;
                $.post(lien, {
                    'nb': stock,
                    'numero': numero
                }).done(function (data) {
                    $(event.target).val(data.new);
                    general.nbpanier += data.diff;
                    app.Loading = false;

                });
            }, 500,this,event, lien, numero);
        },


        SendAdd: function SendAdd(event,ajouter, numero, stock,elem) {
            var app = this;
            this.Loading = true;

            if (stock > 0 || !ajouter) {

                $.post(lien_panier_modif, {
                    'ajouter': ajouter ? '1' : '0',
                    'numero': numero
                }).done(function (data) {
                    elem.val(data.new);
                    general.nbpanier += data.diff;
                    app.Loading = false;
                });
            } else if (lien_add_panier && id_article) {
                this.AddPanier(id_article, lien_add_panier);
            }
        },
        ConfirmCommand: function ConfirmCommand() {
            if (confirm("Êtes-vous certain de vouloir valider cette commande ?")) {
                window.location.href = "/validate";
            }
        }
    },
    mounted: function mounted() {
        $('body').css("visibility", "visible");
    }
});
$(document).ajaxError(function (event, xhr) {
    client.Loading = false;

    if (xhr.status === 500) {
        alert('Une erreur serveur est survenue.');
    }
});
$(document).ajaxSuccess(function (event, xhr, settings) {
    if (xhr.responseText == "erreur") {
        alert('Impossible de traiter cette demande.');
    }
});


$( document ).ajaxError(function( event, xhr ) {
    client.Loading = false;
    if(xhr.status === 500)
    {
        alert('Une erreur serveur est survenue.');
    }
});

$( document ).ajaxSuccess(function( event, xhr, settings ) {
    if(xhr.responseText == "erreur")
    {
        alert('Impossible de traiter cette demande.');
    }
});
